//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import appStyles              from '@/styles.module.scss';
import ComponentHelper        from '@/helper/ComponentHelper';
import Ids                    from '@/constants/Ids';
import PropTypes              from '@/components/PropTypes';
import { ProfileActions }     from '@/store/actions/profile';
import Routes                 from '@/constants/Routes';
import { UserActions }        from '@/store/actions/user';
import ProfileSideMenu        from '@/components/connected/ProfileSideMenu';
import { TagActions }         from '@/store/actions/tag';

import State                 from '@/helper/State';
import I18n                  from 'i18next';
import { CompanyActions }    from '@/store/actions/company';
import { ColorBox }          from '@/components/stateless/atomic/ColorBox';
import IconType              from '@/components/stateless/atomic/Icon/IconType';
import AttachmentUpload      from '@/components/stateless/composed/AttachmentUpload';
import { ColorButton }       from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme      from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ColorButtonSize       from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import { Spacer }            from '@/components/stateless/atomic/Spacer';
import AllowedFileType       from '@/constants/AllowedFileType';
import { InformationBox }    from '@/components/stateless/composed/InformationBox';
import UserVerificationState from '@/constants/UserVerificationState';
import styles                from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmProfileDelete: false,
        };

        this.props.fetch();
        this.props.loadInitialProfileData();
    }

    submitVerification = () => {
        this.props.createUserVerification();
    };

    updateIdentityCard = (identityCard) => {
        this.props.setIDCardImage({
            identityCard,
        });
    };

    onIdentityCardFileChanged = (files) => {
        const identityCard = _.last(files);

        if (identityCard) {
            this.updateIdentityCard(identityCard);
        }
    };

    onIdentityCardFileDeleted = () => {
        this.updateIdentityCard(null);
    };

    updateBusinessCard = (businessCard) => {
        this.props.setBusinessCardImage({
            businessCard,
        });
    };

    onBusinessCardFileChanged = (files) => {
        const businessCard = _.last(files);

        if (businessCard) {
            this.updateBusinessCard(businessCard);
        }
    };

    onBusinessCardFileDeleted = () => {
        this.updateBusinessCard(null);
    };

    getVerificationState = () => {
        return _.get(this.props, 'userVerificationRequest.state');
    };

    isVerificationRequestDeclined = () => {
        return this.getVerificationState() === UserVerificationState.declined;
    };

    renderStateInformation = () => {
        if (!this.getVerificationState()) {
            return null;
        }

        const headlineKey = _.camelCase(`userVerificationHeadline ${this.getVerificationState()}`);
        const sublineKey  = _.camelCase(`userVerificationSubline ${this.getVerificationState()}`);

        return (
            <>
                <Spacer height={15} />
                <InformationBox
                    headline={I18n.t(headlineKey)}
                    subline={I18n.t(sublineKey)}
                />
                <Spacer height={15} />
            </>
        );
    };

    shouldSubmitButtonBeDisabled = () => {
        if (!this.getVerificationState()) {
            return false;
        }

        return !this.isVerificationRequestDeclined();
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <ProfileSideMenu
                        route={Routes.myProfileVerification}
                        {...this.props}
                        openMyProfileProjects={this.props.openMyProfileProjects}
                        openMyProfileMessages={this.props.openMyProfileMessages}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <div className={styles.myProfileVerificationContainer}>
                            <div className={styles.myProfileVerificationContent}>
                                <h3>
                                    {I18n.t('userVerificationTitle')}
                                </h3>
                                {this.renderStateInformation()}
                                <ColorBox>
                                    <div className={styles.myProfileVerificationContentText}>
                                        {I18n.t('userVerificationInformationText')}
                                        <ul>
                                            <li>{I18n.t('userVerificationInformationIDText')}</li>
                                            <li>{I18n.t('userVerificationInformationBusinessCardText')}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <AttachmentUpload
                                            headline={I18n.t('userVerificationIDUpload')}
                                            attachedFilesTitle={I18n.t('attachedImages')}
                                            buttonText={I18n.t('orSelectFile')}
                                            buttonIcon={IconType.upload}
                                            files={[_.get(this.props, 'userVerificationRequest.identityCard')]}
                                            renderTitleInput={false}
                                            onFilesChanged={this.onIdentityCardFileChanged}
                                            onFileDeleted={this.onIdentityCardFileDeleted}
                                            iconType={IconType.upload}
                                            renderUploadButton={true}
                                            accept={AllowedFileType.image}
                                        />
                                    </div>
                                    <div>
                                        <AttachmentUpload
                                            headline={I18n.t('userVerificationBusinessCardUpload')}
                                            attachedFilesTitle={I18n.t('attachedImages')}
                                            buttonText={I18n.t('orSelectFile')}
                                            buttonIcon={IconType.upload}
                                            files={[_.get(this.props, 'userVerificationRequest.businessCard')]}
                                            renderTitleInput={false}
                                            onFilesChanged={this.onBusinessCardFileChanged}
                                            onFileDeleted={this.onBusinessCardFileDeleted}
                                            iconType={IconType.camera}
                                            renderUploadButton={true}
                                            accept={AllowedFileType.image}
                                        />
                                    </div>
                                    <div className={styles.myProfileVerificationContentFooterText}>
                                        {I18n.t('userVerificationInformationNote')}
                                    </div>
                                    <Spacer height={50} />
                                    <ColorButton
                                        grow={true}
                                        theme={ColorButtonTheme.orange}
                                        size={ColorButtonSize.default}
                                        text={I18n.t('userVerificationSubmitButton')}
                                        onClick={this.submitVerification}
                                        disabled={this.shouldSubmitButtonBeDisabled()}
                                    />
                                </ColorBox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, Screen, nextProps, nextState);
    }
}

Screen.propTypes = {
    companyId:                PropTypes.number,
    confirmCompanyDelete:     PropTypes.func,
    createUserVerification:   PropTypes.func,
    deleteProfile:            PropTypes.func,
    fetch:                    PropTypes.func,
    history:                  PropTypes.object,
    isUserWithoutCompany:     PropTypes.bool,
    leaveCompany:             PropTypes.func,
    loadInitialProfileData:   PropTypes.func,
    openMyProfileMessages:    PropTypes.func,
    openMyProfileProjects:    PropTypes.func,
    setBusinessCardImage:     PropTypes.func,
    setIDCardImage:           PropTypes.func,
    showConfirmCompanyDelete: PropTypes.bool,
    user:                     PropTypes.object,
    userVerificationRequest:  PropTypes.object,
};

Screen.defaultProps = {
    companyId:                null,
    confirmCompanyDelete:     _.noop,
    createUserVerification:   _.noop,
    deleteProfile:            _.noop,
    fetch:                    _.noop,
    history:                  {},
    isUserWithoutCompany:     false,
    leaveCompany:             _.noop,
    loadInitialProfileData:   _.noop,
    openMyProfileMessages:    _.noop,
    openMyProfileProjects:    _.noop,
    setBusinessCardImage:     _.noop,
    setIDCardImage:           _.noop,
    showConfirmCompanyDelete: false,
    user:                     null,
    userVerificationRequest:  null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [
    'showConfirmProfileDelete',
];

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...UserActions,
    ...CompanyActions,
    ...ProfileActions,
    ...TagActions,
}, dispatch);

const mapStateToProps = (state) => {
    const user                     = _.get(state, 'user');
    const isUserWithoutCompany     = _.isNil(State.getUserCompany(state));
    const companyId                = State.getUserCompanyId(state);
    const showConfirmCompanyDelete = _.get(state, 'company.confirmCompanyDelete', false);
    const userVerificationRequest  = _.get(user, 'userVerificationRequest', null);

    return {
        user,
        isUserWithoutCompany,
        companyId,
        showConfirmCompanyDelete,
        userVerificationRequest,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Screen);
