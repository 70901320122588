//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import I18n            from 'i18next';
import ColorBox        from '@/components/stateless/atomic/ColorBox';
import ComponentHelper from '@/helper/ComponentHelper';
import FormRow         from '@/components/stateless/composed/FormRow';
import HeadlineMedium  from '@/components/stateless/atomic/HeadlineMedium';
import ImageUpload     from '@/components/stateless/atomic/ImageUpload';
import ImageUploadSize from '@/components/stateless/atomic/ImageUpload/ImageUploadSize';
import Overlay         from '@/components/connected/Overlay';
import Overlays        from '@/constants/Overlays';
import OverlayType     from '@/components/connected/Overlay/OverlayType';
import PropTypes       from '@/components/PropTypes';
import TextInput       from '@/components/stateless/atomic/TextInput';
import File            from '@/helper/File';
import TagSearchEditor from '@/components/connected/TagSearchEditor';
import TagDropDown     from '@/components/connected/TagDropDown';
import TagTypeDropDown from '@/constants/TagTypeDropDown';

import TagEditorContext from '@/constants/TagEditorContext';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const props = this.props;

        return (
            <Overlay
                id={Overlays.addNewProduct}
                okButtonPressed={props.okButtonPressed}
                okButtonText={I18n.t('save')}
                overlayType={OverlayType.full}
                closeButtonPressed={props.closeButtonPressed}
                okButtonValidator={props.okButtonValidator}
                disableCloseOnOutsideClick={true}
            >
                {this.renderHeader()}
                {this.renderBody()}
            </Overlay>
        );
    }

    updateField = (field, value) => {
        this.props.editProductField({
            field,
            value,
        });
    };

    onImageUpload = (uploadedImage) => {
        if (uploadedImage) {
            File
                .getFileFromBlob(uploadedImage)
                .then((logo) => {
                    this.updateField('image', logo);
                });
        } else {
            this.updateField('image', uploadedImage);
        }
    };

    onImageDelete = () => {
        this.onImageUpload(null);
    };

    onTitleChanged = (event) => {
        const value = event.target.value;

        this.updateField('title', value);
    };

    onCategoryChanged = (event) => {
        const value = event.target.value;

        this.updateField('category', value.replace(/[^a-zA-Z\d\s:]/g, ''));
    };

    onTagSelected = (tag) => {
        this.updateField('tag', tag);
    };

    renderHeader = () => {
        let title            = I18n.t('editProductTitle');
        const { ownProduct } = this.props;

        if (!_.has(ownProduct, 'id')) {
            title = I18n.t('createProductTitle');
        }

        return (
            <div className={styles.addNewProductHeaderContainer}>
                <HeadlineMedium text={title} />
            </div>
        );
    };

    renderBody = () => {
        const { ownProduct } = this.props;
        const image          = _.get(ownProduct, 'image', null);
        const title          = _.get(ownProduct, 'title', '');
        const tag            = _.get(ownProduct, 'tag', null);
        const category       = _.get(ownProduct, 'category', '');

        return (
            <div className={styles.addNewProductBodyContainer}>
                <ColorBox>
                    <div className={styles.addNewProductBodyBaseContainer}>
                        <div className={styles.addNewProductBodyImageContainer}>
                            <FormRow label={I18n.t('image')}>
                                <ImageUpload
                                    uploadFileCallback={this.onImageUpload}
                                    deleteFileCallback={this.onImageDelete}
                                    images={[image]}
                                    size={ImageUploadSize.large}
                                />
                            </FormRow>
                        </div>
                        <div className={styles.addNewProductBodyTextContainer}>
                            <FormRow label={I18n.t('title')}>
                                <TextInput
                                    value={title}
                                    onChange={this.onTitleChanged}
                                />
                            </FormRow>
                            <div className={styles.addNewProductBodyDescriptionContainer}>
                                <FormRow label={I18n.t('tag')}>
                                    <TagDropDown
                                        value={tag}
                                        valueChanged={this.onTagSelected}
                                        tagType={TagTypeDropDown.partFamily}
                                    />
                                </FormRow>
                            </div>
                            <div className={styles.addNewProductBodyDescriptionContainer}>
                                <FormRow label={I18n.t('category')}>
                                    <TextInput
                                        value={category}
                                        onChange={this.onCategoryChanged}
                                    />
                                    <p>
                                        {I18n.t('categoryProductHelpText')}
                                    </p>
                                </FormRow>
                            </div>
                        </div>
                    </div>
                </ColorBox>
                <TagSearchEditor
                    tagContext={TagEditorContext.newProduct}
                />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewProductOverlay = Component;

Component.propTypes = {
    closeButtonPressed: PropTypes.func,
    editProductField:   PropTypes.func,
    okButtonPressed:    PropTypes.func,
    okButtonValidator:  PropTypes.func,
    ownProduct:         PropTypes.object,
};

Component.defaultProps = {
    closeButtonPressed: _.noop,
    editProductField:   _.noop,
    okButtonPressed:    _.noop,
    okButtonValidator:  _.noop,
    ownProduct:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
