//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import _                           from 'lodash';
import { bindActionCreators }      from 'redux';
import { compose }                 from 'redux';
import { connect }                 from 'react-redux';
import ComponentHelper             from '@/helper/ComponentHelper';
import File                        from '@/helper/File';
import PropTypes                   from '@/components/PropTypes';
import StatelessEditCompanyOverlay from '@/components/stateless/composed/EditCompanyOverlay';
import Tag                         from '@/helper/Tag';
import { CompanyActions }          from '@/store/actions/company';
import { CompanyTypeActions }      from '@/store/actions/companyType';
import { TagActions }              from '@/store/actions/tag';
import { BoldTranslation }         from '@/components/stateless/composed/BoldTranslation';
import I18n                        from 'i18next';
import { OverlayActions }          from '@/store/actions/ovleray';
import { AlertBoxActions }         from '@/store/actions/alertBox';

export class Component extends React.Component {
    okButtonPressed = () => {
        this.props.updateCompany();
    };

    okButtonValidator = () => {
        if (this.props.companyCompanyTypes?.length <= 0) {
            this.props.showErrorAlert({
                text: I18n.t('myCompanyMissingTypes'),
            });

            return false;
        }

        if (this.props.editCompetencesHierarchy?.length < 3) {
            this.props.showErrorAlert({
                text: I18n.t('myCompanyMissingMinimum3Competences'),
            });

            return false;
        }

        return true;
    };

    onCompanyAddressCityChanged = (event) => {
        this.props.editOwnCompanyAddressCity({
            city: event.target.value,
        });
    };

    onCompanyAddressCountryChanged = (companyCountryOption) => {
        this.props.editOwnCompanyAddressCountry({
            country:     companyCountryOption.label,
            countryCode: companyCountryOption.value,
        });
    };

    onCompanyAddressHouseNumberChanged = (event) => {
        this.props.editOwnCompanyAddressHouseNumber({
            houseNumber: event.target.value,
        });
    };

    onCompanyAddressPostalCodeChanged = (event) => {
        this.props.editOwnCompanyAddressPostalCode({
            postalCode: event.target.value,
        });
    };

    onCompanyAddressStateChanged = (event) => {
        this.props.editOwnCompanyAddressState({
            state: event.target.value,
        });
    };

    onCompanyAddressStreetChanged = (event) => {
        this.props.editOwnCompanyAddressStreet({
            street: event.target.value,
        });
    };

    onCompanyContactPhoneChanged = (event) => {
        this.props.editOwnCompanyContactPhone({
            phoneNumber: event.target.value,
        });
    };

    onCompanyDescriptionChanged = (event) => {
        this.props.editOwnCompanyDescription({
            description: event.target.value,
        });
    };

    onCompanyNameChanged = (event) => {
        this.props.editOwnCompanyName({
            name: event.target.value,
        });
    };

    onCompanyPresentationChanged = (presentation) => {
        this.props.editOwnCompanyPresentation({
            presentation,
        });
    };

    onCompanyVideoChanged = (video) => {
        this.props.editOwnCompanyVideo({
            video,
        });
    };

    onCompanyTypeChanged = (companyTypeOption) => {
        this.props.editOwnCompanyCompanyTypes({
            companyTypes: _.map(companyTypeOption, 'value'),
        });
    };

    onCompanyIndustryChanged = (companyIndustryOptions) => {
        this.props.editOwnCompanyCompanyIndustries({
            companyIndustries: companyIndustryOptions,
        });
    };

    onImageChanged = (uploadedLogo) => {
        if (uploadedLogo) {
            File
                .getFileFromBlob(uploadedLogo)
                .then((logo) => {
                    this.props.editOwnCompanyLogo({
                        logo,
                    });
                });
        }
    };

    onTagSearch = (event) => {
        const value = event.target.value;

        this.props.fetchTags({
            title: value,
        });
    };

    onCompanyCompetenceTagsAdd = (tag) => {
        const newTag = tag.tag ? tag.tag : tag;

        this.props.addCompanyCompetenceTag({
            tag: newTag,
        });
    };

    onCompanyCompetenceTagsDelete = (tag) => {
        this.props.deleteCompanyCompetenceTag({
            tag,
        });
    };

    onDeleteCompany = () => {
        const { companyName, companyId } = this.props;

        const message = (
            <BoldTranslation
                translationKey={'deleteCompanyText'}
                values={{
                    companyName,
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                CompanyActions.deleteCompany({
                    companyId,
                }),
            ],
            title:             I18n.t('deleteCompanyTitle'),
            message,
            confirmButtonText: I18n.t('delete'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    render() {
        return (
            <StatelessEditCompanyOverlay
                okButtonPressed={this.okButtonPressed}
                okButtonValidator={this.okButtonValidator}
                onCompanyAddressCityChanged={this.onCompanyAddressCityChanged}
                onCompanyAddressCountryChanged={this.onCompanyAddressCountryChanged}
                onCompanyAddressHouseNumberChanged={this.onCompanyAddressHouseNumberChanged}
                onCompanyAddressPostalCodeChanged={this.onCompanyAddressPostalCodeChanged}
                onCompanyAddressStateChanged={this.onCompanyAddressStateChanged}
                onCompanyAddressStreetChanged={this.onCompanyAddressStreetChanged}
                onCompanyCompetenceTagsAdd={this.onCompanyCompetenceTagsAdd}
                onCompanyCompetenceTagsDelete={this.onCompanyCompetenceTagsDelete}
                onCompanyContactPhoneChanged={this.onCompanyContactPhoneChanged}
                onCompanyDescriptionChanged={this.onCompanyDescriptionChanged}
                onCompanyNameChanged={this.onCompanyNameChanged}
                onCompanyTypeChanged={this.onCompanyTypeChanged}
                onCompanyIndustryChanged={this.onCompanyIndustryChanged}
                onCompanyPresentationChanged={this.onCompanyPresentationChanged}
                onCompanyVideoChanged={this.onCompanyVideoChanged}
                onCompanyDelete={this.onDeleteCompany}
                onImageChanged={this.onImageChanged}
                onTagSearch={this.onTagSearch}
                tagSearchQuery={this.props.tagSearchQuery}
                resetTagQuery={this.props.resetTagQuery}
                competencesResultList={this.props.competencesResultList}
                companyLastUpdate={this.props.companyLastUpdate}
                companyLogo={this.props.companyLogo}
                companyName={this.props.companyName}
                companyAddress={this.props.companyAddress}
                companyContact={this.props.companyContact}
                companyDescription={this.props.companyDescription}
                companyCompanyTypes={this.props.companyCompanyTypes}
                companyIndustries={this.props.companyIndustries}
                companyTypes={this.props.companyTypes}
                companyCompetenceTags={this.props.competencesHierarchy}
                companyVideo={this.props.companyVideo}
                companyPresentation={this.props.companyPresentation}
                isLoading={this.props.isLoading}
            >
            </StatelessEditCompanyOverlay>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const EditCompanyOverlay = Component;

Component.propTypes = {
    addCompanyCompetenceTag:          PropTypes.func,
    addCompanyCompetenceTagQuery:     PropTypes.func,
    companyAddress:                   PropTypes.object,
    companyCompanyTypes:              PropTypes.array,
    companyContact:                   PropTypes.object,
    companyDescription:               PropTypes.string,
    companyId:                        PropTypes.string,
    companyIndustries:                PropTypes.array,
    companyLastUpdate:                PropTypes.string,
    companyLogo:                      PropTypes.object,
    companyName:                      PropTypes.string,
    companyPresentation:              PropTypes.object,
    companyTypes:                     PropTypes.array,
    companyVideo:                     PropTypes.object,
    competencesHierarchy:             PropTypes.array,
    competencesResultList:            PropTypes.array,
    editCompetencesHierarchy:         PropTypes.array,
    deleteCompanyCompetenceTag:       PropTypes.func,
    editOwnCompanyAddressCity:        PropTypes.func,
    editOwnCompanyAddressCountry:     PropTypes.func,
    editOwnCompanyAddressHouseNumber: PropTypes.func,
    editOwnCompanyAddressPostalCode:  PropTypes.func,
    editOwnCompanyAddressState:       PropTypes.func,
    editOwnCompanyAddressStreet:      PropTypes.func,
    editOwnCompanyCompanyIndustries:  PropTypes.func,
    editOwnCompanyCompanyTypes:       PropTypes.func,
    editOwnCompanyContactPhone:       PropTypes.func,
    editOwnCompanyDescription:        PropTypes.func,
    editOwnCompanyHouseNumberStreet:  PropTypes.func,
    editOwnCompanyLogo:               PropTypes.func,
    editOwnCompanyName:               PropTypes.func,
    editOwnCompanyPresentation:       PropTypes.func,
    editOwnCompanyVideo:              PropTypes.func,
    fetchCompanyTypes:                PropTypes.func,
    fetchTags:                        PropTypes.func,
    isLoading:                        PropTypes.bool,
    openConfirmDialog:                PropTypes.func,
    resetTagQuery:                    PropTypes.func,
    showErrorAlert:                   PropTypes.func,
    tagSearchQuery:                   PropTypes.string,
    updateCompany:                    PropTypes.func,
};

Component.defaultProps = {
    addCompanyCompetenceTag:          _.noop,
    addCompanyCompetenceTagQuery:     _.noop,
    companyAddress:                   null,
    companyCompanyTypes:              [],
    companyContact:                   null,
    companyDescription:               null,
    companyId:                        null,
    companyIndustries:                [],
    companyLastUpdate:                null,
    companyLogo:                      null,
    companyName:                      null,
    companyPresentation:              null,
    companyTypes:                     [],
    companyVideo:                     null,
    competencesHierarchy:             [],
    competencesResultList:            [],
    editCompetencesHierarchy:         [],
    deleteCompanyCompetenceTag:       _.noop,
    editOwnCompanyAddressCity:        _.noop,
    editOwnCompanyAddressCountry:     _.noop,
    editOwnCompanyAddressHouseNumber: _.noop,
    editOwnCompanyAddressPostalCode:  _.noop,
    editOwnCompanyAddressState:       _.noop,
    editOwnCompanyAddressStreet:      _.noop,
    editOwnCompanyCompanyIndustries:  _.noop,
    editOwnCompanyCompanyTypes:       _.noop,
    editOwnCompanyContactPhone:       _.noop,
    editOwnCompanyDescription:        _.noop,
    editOwnCompanyHouseNumberStreet:  _.noop,
    editOwnCompanyLogo:               _.noop,
    editOwnCompanyName:               _.noop,
    editOwnCompanyPresentation:       _.noop,
    editOwnCompanyVideo:              _.noop,
    fetchCompanyTypes:                _.noop,
    fetchTags:                        _.noop,
    isLoading:                        false,
    openConfirmDialog:                _.noop,
    resetTagQuery:                    _.noop,
    showErrorAlert:                   _.noop,
    tagSearchQuery:                   null,
    updateCompany:                    _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...AlertBoxActions,
        ...CompanyActions,
        ...CompanyTypeActions,
        ...OverlayActions,
        ...TagActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const allTagResults         = _.get(state, 'tag.tags', []);
    const competencesHierarchy  = _.get(state, 'company.ownCompanyEdit.competencesHierarchy', []);
    const competencesResultList = Tag.filterAllTagsFromHierarchy(allTagResults, competencesHierarchy);

    return {
        companyId:                _.get(state, 'company.ownCompanyEdit.id'),
        companyAddress:           _.get(state, 'company.ownCompanyEdit.address'),
        companyCompanyTypes:      _.get(state, 'company.ownCompanyEdit.types'),
        companyIndustries:        _.get(state, 'company.ownCompanyEdit.industries'),
        companyContact:           _.get(state, 'company.ownCompanyEdit.contactData'),
        companyDescription:       _.get(state, 'company.ownCompanyEdit.description'),
        companyLastUpdate:        _.get(state, 'company.ownCompanyEdit.updatedAt'),
        companyLogo:              _.get(state, 'company.ownCompanyEdit.logo'),
        companyName:              _.get(state, 'company.ownCompanyEdit.name'),
        companyVideo:             _.get(state, 'company.ownCompanyEdit.video'),
        companyPresentation:      _.get(state, 'company.ownCompanyEdit.presentation'),
        companyTypes:             _.get(state, 'companyType.companyTypes', []),
        editCompetencesHierarchy: _.get(state, 'tag.editor.editCompanyCompetences.tagHierarchy', []),
        isLoading:                _.get(state, 'tag.isLoading'),
        competencesHierarchy,
        competencesResultList,
        tagSearchQuery:           _.get(state, 'tag.tagQuery'),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
