//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import { bindActionCreators }        from 'redux';
import { compose }                   from 'redux';
import { connect }                   from 'react-redux';
import I18n                          from 'i18next';
import AddNewProductOverlayStateless from '@/components/stateless/composed/AddNewProductOverlay';
import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import { ProductActions }            from '@/store/actions/product';
import { AlertBoxActions }           from '@/store/actions/alertBox';

export class Component extends React.Component {
    onOkButtonPressed = () => {
        this.props.saveProduct();
    };

    onOkButtonValidator = () => {
        const { ownProduct } = this.props;
        const title          = _.get(ownProduct, 'title', null);
        const image          = _.get(ownProduct, 'image', null);
        let isValid          = true;

        if (!title) {
            this.props.showErrorAlert({
                text: I18n.t('saveProductTitleMissing'),
            });

            isValid = false;
        }

        if (!image) {
            this.props.showErrorAlert({
                text: I18n.t('saveProductImageMissing'),
            });

            isValid = false;
        }

        return isValid;
    };

    render() {
        return (
            <AddNewProductOverlayStateless
                okButtonPressed={this.onOkButtonPressed}
                okButtonValidator={this.onOkButtonValidator}
                ownProduct={this.props.ownProduct}
                editProductField={this.props.editProductField}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewProductOverlay = Component;

Component.propTypes = {
    editProductField: PropTypes.func,
    ownProduct:       PropTypes.object,
    saveProduct:      PropTypes.func,
    showErrorAlert:   PropTypes.func,
};

Component.defaultProps = {
    editProductField: _.noop,
    ownProduct:       null,
    saveProduct:      _.noop,
    showErrorAlert:   _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...ProductActions,
        ...AlertBoxActions,
    },
    dispatch,
);

const mapStateToProps = (state) => (
    {
        ownProduct: _.get(state, 'product.ownProductEdit'),
    }
);

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
