//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import _           from 'lodash';
import queryString from 'query-string';
import { connect } from 'react-redux';
import PropTypes   from '@/components/PropTypes';

export class Component extends React.Component {
    getNextRoute = (parsedQueryString) => {
        return Component.getNextRouteInternal(this.props.location, undefined, parsedQueryString);
    };

    static getPathForOverlayKey(overlayKey, route = null) {
        return this.getNextRouteInternal(window.location, overlayKey, undefined, route);
    }

    static getNextRouteInternal(location, overlayKey, parsedQueryString = queryString.parse(location.search), path) {
        const nextParsedQueryString   = _.cloneDeep(parsedQueryString);
        nextParsedQueryString.overlay = overlayKey;
        const nextQueryString         = queryString.stringify(nextParsedQueryString);
        const route                   = path || location.pathname;
        const search                  = nextQueryString.length > 0 ? `?${nextQueryString}` : '';

        return {
            route,
            search,
        };
    }

    render() {
        const overlays          = this.props.overlays;
        const parsedQueryString = queryString.parse(this.props.location.search);

        console.log('OverlayManager: render: parsedQueryString', parsedQueryString);
        console.log('OverlayManager: render: overlays', overlays);

        if (parsedQueryString.overlay) {
            for (const currentOverlayKey in overlays) {
                if (currentOverlayKey === parsedQueryString.overlay) {
                    console.log('OverlayManager: render: showing overlay', currentOverlayKey);

                    const nextRoute       = this.getNextRoute(parsedQueryString);
                    const OverlayInstance = overlays[parsedQueryString.overlay];

                    return (
                        <OverlayInstance
                            key={currentOverlayKey}
                            nextRoute={nextRoute}
                        />
                    );
                }
            }
        }

        return null;
    }
}

export const OverlayManager = Component;

Component.propTypes = {
    location: PropTypes.object,
    overlays: PropTypes.object,
};

Component.defaultProps = {
    location: {},
    overlays: null,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        location: _.get(state, 'router.location'),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
